import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

import { Link } from './Text';

export default ({ children, href }) => (
  <Link
    as={ScrollLink}
    key={href}
    to={href}
    offset={-80}
    smooth="easeOutQuart"
    duration={1000}
  >
  {children}
  </Link>
);
