import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 90%;
  }
  
  @media (min-width: ${({ theme }) => theme.breakpoints[6]}) {
    width: 750px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[7]}) {
    width: 970px;
  }
  
  @media (min-width: ${({ theme }) => theme.breakpoints[8]}) {
    width: 1170px;
  }
`;

const SiteWidth = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
);

SiteWidth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SiteWidth;
