import styled from 'styled-components';

import Paragraph from './Paragraph';

const Label = styled(Paragraph)`
  font-size: ${props => (props.size)};
  font-weight: ${props => props.fontWeight || '300'};
  color: ${props => props.textColor || 'black'};
  letter-spacing: ${props => props.letterSpacing || '0.35rem'};
  text-indent: ${props => props.textIndent || '4px'};
  line-height: ${props => props.lineHeight || '16px'};
  transform: ${props => props.transform || 'none'};
  width: ${props => props.width || 'auto'};
  max-width: ${props => props.maxWidth || 'auto'};
  border-color: ${props => props.borderColor || 'none'};
  background: ${props => props.backgroundColor || 'none'};
  white-space: nowrap;
`;

export default Label;
