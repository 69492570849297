import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Accordion from '../components/Accordion';

export default () => (
  <StaticQuery
    query={graphql`
      query ServicesQuery {
        file(relativePath: {regex: "/services/"}) {
          childMarkdownRemark {
            frontmatter {
              service {
                title
                content
              }
            }
          }
        }
      }
    `}
    render={({ file }) => (
        <Accordion
          items={file.childMarkdownRemark.frontmatter.service}
        />
      )}
  />
);
