import styled from 'styled-components';
import { ease } from '../../utils';

import Paragraph from './Paragraph';

const Link = styled(Paragraph)`
  position: relative;
  cursor: pointer;
  font-weight: 600;

  color: black;
  &:after {
    content: "";
    position: absolute;
    left: 3px; right: 3px; bottom: -5px;
    border-bottom: 1px solid white;
    transform: scaleX(0);
    opacity: 0;
    transition: transform 800ms ${ease('out', 'expo')}, opacity 400ms;
  }
  &:hover:after {
    transform: scaleX(1);
    opacity: 0.7;
  }
`;

export default Link;
