import React from 'react';
import styled from 'styled-components';
import { useMedia } from 'react-use-media';

import Page from '../components/Page';
import Footer from '../components/Footer';
import Header from '../components/Header';
import FloatingButton from '../components/FloatingButton';

import Banner from '../structure/Banner';
import Services from '../structure/Services';
import FirstSection from '../structure/Sections/First';
import SecondSection from '../structure/Sections/Second';
import ThirdSection from '../structure/Sections/Third';
import FourthSection from '../structure/Sections/Fourth';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const BlueWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.base};
  display: flex;
  justify-content: center;
  margin: 50px 0;
  padding: 50px 0;
`;

const IndexPage = () => {
  const isDesktop = useMedia({
    minWidth: 768,
  });
  return (
    <Page>
      <Wrapper>
        {isDesktop ? <Header /> : <FloatingButton />}
        <Banner />
        <FirstSection />
        <SecondSection />
        <Services />
        <BlueWrapper>
          <ThirdSection />
        </BlueWrapper>
        <FourthSection />
        <Footer />
      </Wrapper>
    </Page>
  );
};

export default IndexPage;
