import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Fab from '@material-ui/core/Fab';
import PhoneEnabledSharpIcon from '@material-ui/icons/PhoneEnabledSharp';
import PhoneAndroidSharpIcon from '@material-ui/icons/PhoneAndroidSharp';
import { grey } from '@material-ui/core/colors';

const PHONE_NUMBERS = [
  { label: 'Nenad', href: 'tel:0646444701' },
  { label: 'Viktor', href: 'tel:0646444703' },
  { label: 'Boris', href: 'tel:0646444709' },
  { label: 'Ambulanta', href: 'tel:013333513' },
];

const Wrapper = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 999999999999;
`;

const Items = styled.div`
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  position: fixed;
  bottom: 25px;
  right: 32px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 500ms;

  ${props => props.open && css`
    opacity: 1;
    transform: translateY(-${props.bottom + 30}px);
  `}
`;

const Label = styled.div`
  min-width: 100px;
  text-align: center;
  background: ${grey['300']};
  border-radius: 4px;
  padding: 7px;
  margin-right: 15px;
  box-shadow:
    0px 3px 5px -1px rgba(0,0,0,0.2),
    0px 6px 10px 0px rgba(0,0,0,0.14),
    0px 1px 18px 0px rgba(0,0,0,0.12);
`;

const createItems = (items, open) => items.map((item, i) => (
  <a key={item.label} href={item.href}>
    <Item bottom={i * 50 + 25} open={open}>
    <Label>{item.label}</Label>
    <Fab
      color="default"
      size="small"
    >
      <PhoneAndroidSharpIcon />
    </Fab>
    </Item>
  </a>
));

export default (() => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Wrapper>
      <Items>
        {createItems(PHONE_NUMBERS, isOpen)}
      </Items>
      <Fab
        as="a"
        color="default"
        aria-label="call"
        onClick={() => setOpen(!isOpen)}
      >
        <PhoneEnabledSharpIcon />
      </Fab>
    </Wrapper>
  );
});
