import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Section from '../../components/Section';

export default () => (
  <StaticQuery
    query={graphql`
      query FourthSectionQuery {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: {regex: "/sections/"}
            frontmatter: {
              sort: { eq: 40 }
            }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
              html
            }
          }
        }
      }
    `}
    render={(data) => {
      const allData = data.allMarkdownRemark.edges[0].node;
      return (
        <Section
          id={allData.frontmatter.title}
          title={allData.frontmatter.title}
          item={allData.html}
        />
      );
    }}
  />
);
