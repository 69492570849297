/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import ReactHeadroom from 'react-headroom';

const HeadroomContext = React.createContext('headroom');

class Headroom extends Component {
  state = { pinned: false, fixed: false, transition: false };

  handlePin = () => {
    this.setState({ pinned: true, fixed: true });
  };

  handleUnpin = () => {
    this.setState({ pinned: false, fixed: true }, () => {
      setTimeout(() => {
        this.setState({ transition: true });
      }, 0);
    });
  };

  handleUnfix = () => {
    this.setState({
      pinned: false,
      fixed: false,
      transition: false,
    });
  };

  render() {
    const {
      className,
      disable,
      pinStart,
      upTolerance,
      downTolerance,
      parent,
      children,
    } = this.props;
    return (
      <HeadroomContext.Provider value={this.state}>
        <ReactHeadroom
          className={className}
          disableInlineStyles
          disable={disable}
          pinStart={pinStart}
          upTolerance={upTolerance}
          downTolerance={downTolerance}
          parent={parent}
          onPin={this.handlePin}
          onUnpin={this.handleUnpin}
          onUnfix={this.handleUnfix}
        >
          <HeadroomContext.Consumer>
            {children}
          </HeadroomContext.Consumer>
        </ReactHeadroom>
      </HeadroomContext.Provider>
    );
  }
}

export default Headroom;
