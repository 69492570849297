import React from 'react';
import styled from 'styled-components';
import {
    Accordion as ReactAccordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import SiteWidth from './SiteWidth';

import { ease } from '../utils';
import { Paragraph } from './Text';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
`;

const StyledAccordion = styled(ReactAccordion)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 70%;
  }
`;

const StyledItem = styled(AccordionItem)`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const StyledHeading = styled(AccordionItemHeading)`
  width: 100%;
`;

const Arrow = styled.div`
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 1px solid currentColor;
  border-right: 1px solid currentColor;
  transform: rotate(-45deg);
  transition: transform 200ms ${ease('out', 'quad')};
`;

const Text = styled(Paragraph)`
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 500;
`;

const StyledButton = styled(AccordionItemButton)`
  outline: none;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-tap-highlight-color: transparent;

  &[aria-expanded="true"] > #styledarrow,
  &[aria-selected="true"] > #styledarrow {
    transform: rotate(45deg);
  }
`;

const StyledPanel = styled(AccordionItemPanel)`
  width: 100%;
  padding: 20px;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 20px;
  animation: fadein 300ms ${ease('in', 'quad')};

  @keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
`;

const createAccordionItems = items => items.map(item => (
  <StyledItem key={item.title}>
    <StyledHeading>
      <StyledButton>
        <Text>
          {item.title}
        </Text>
        <Arrow id="styledarrow" />
      </StyledButton>
    </StyledHeading>
    <StyledPanel>
      {item.content}
    </StyledPanel>
  </StyledItem>
));

const Accordion = ({ items }) => (
  <SiteWidth>
    <Wrapper>
      <StyledAccordion
        allowZeroExpanded
        allowMultipleExpanded
      >
        {createAccordionItems(items)}
      </StyledAccordion>
    </Wrapper>
  </SiteWidth>
);

export default Accordion;
