import React from 'react';
import styled from 'styled-components';
import Phone from '@material-ui/icons/Phone';
import Clock from '@material-ui/icons/AccessTime';
import Mail from '@material-ui/icons/Mail';
import MapPin from '@material-ui/icons/Room';

const Info = styled.a`
  display: flex;
  align-items: center;
  margin: 3px;
  font-size: 12px;
  letter-spacing: 1.5px;
  -webkit-tap-highlight-color: transparent;
`;

const InfoLabel = styled.div`
  color: ${({ theme }) => theme.colors.white.shade[1]};
  margin-left: 18px;
  line-height: 20px;
  font-weight: 500;
`;

const getIcon = (icon, size, opacity) => {
  switch (icon) {
    case 'clock':
      return <Clock fontSize={size} opacity={opacity} />;
    case 'mail':
      return <Mail fontSize={size} opacity={opacity} />;
    case 'map':
      return <MapPin fontSize={size} opacity={opacity} />;
    case 'phone':
      return <Phone fontSize={size} opacity={opacity} />;
    default:
      return null;
  }
};

export default (icon, label, href, blank = false) => (
  <Info href={href} target={blank ? '_blank' : '_self'} rel="noopener">
    {getIcon(icon, 'small', 0.5)}
    <InfoLabel>{label}</InfoLabel>
  </Info>
);
