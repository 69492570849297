import React from 'react';
import styled, { css } from 'styled-components';

import Logo from '../Logo';
import Heading from './Heading';
import Headroom from './Headroom';
import SiteWidth from '../SiteWidth';
import ScrollLink from '../ScrollLink';
import { Paragraph } from '../Text';

const ITEMS = [
  { name: 'O Nama', id: 'O Nama' },
  { name: 'Usluge', id: 'Usluge' },
  { name: 'Dežurni Veterinar', id: 'Dežurni Veterinar' },
  { name: 'Kućne Posete', id: 'Kućne Posete' },
  { name: 'Kontakt', id: 'Kontakt' },
];

const Header = styled(Headroom)`
  position: relative;
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: center;
`;

const Head = styled.div`
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: center;
  z-index: 9999999;
  border-bottom: 1px solid transparent;
  transition: border-color 300ms;

  ${props => props.fixed && css`
    position: fixed;
    width: 100%;
    right: 0; left: 0;
    transform: translateY(-100%);
    background-color: white;
    border-bottom: 1px solid #ccc;
  `}
  ${props => props.transition && css`
    transition: transform 300ms, border-color 300ms;
  `}
  ${props => props.pinned && css`transform: translateY(0);`}
`;

const HeadContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Links = styled.div`
  display: flex;
  font-size: 11px;
`;

const Link = styled(Paragraph)`
  padding-left: 20px;
  letter-spacing: 2px;
`;

const createScrollLinks = links => (
  links.map(item => (
    <ScrollLink
      key={item.name}
      href={item.id}
    >
    <Link>
      {item.name.toUpperCase()}
    </Link>
    </ScrollLink>
  ))
);

export default () => (
  <>
    <Header>
      {({ pinned, fixed, transition }) => (
        <Head
          pinned={pinned}
          fixed={fixed}
          transition={transition}
        >
          <SiteWidth>
          <HeadContent>
            <Logo />
            <Links>
              {createScrollLinks(ITEMS)}
            </Links>
          </HeadContent>
          </SiteWidth>
        </Head>
      )}
    </Header>
    <Heading />
  </>
);
