import React from 'react';
import styled from 'styled-components';
import Mail from '@material-ui/icons/Mail';
import Facebook from '@material-ui/icons/Facebook';

import Logo from '../Logo';
import SiteWidth from '../SiteWidth';

import createInfo from './Info';

const ADDRESS = 'Miloša Trebinjca 44, Pančevo';
const LINK = encodeURIComponent(`Viva Vet d.o.o., ${ADDRESS}`);
const MAP_LINK = `https://www.google.com/maps/dir/?api=1&destination=${LINK}`;

const FooterWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
`;

const WrapperGray = styled.div`
  background-color: #191919;
  opacity: 0.9;
  height: 450px;
  display: flex;
  justify-content: center;
`;

const WrapperBlack = styled.div`
  background-color: #191919;
  height: 100px;
  display: flex;
  justify-content: center;
  color: #ffffff;
`;

const WrapGray = styled.div`
  padding: 3vh 0 5vh 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-direction: column;
  }
`;

const WrapBlack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Left = styled.div`
  color: white;
`;

const Contact = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

`;

const InfoWrapper = styled.div`
  padding-top: 17px;
`;

const Right = styled.div`
  color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    align-self: flex-end;
  }
`;

const Icons = styled.div`
  width: 80px;
  display: flex;
  justify-content: space-between;
`;

const BottomLabel = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.white.shade[1]};
  letter-spacing: 2px;
  margin-bottom: 10px;
`;

const Link = styled.a`
  -webkit-tap-highlight-color: transparent;
`;

export default () => (
  <FooterWrapper id="Kontakt">
    <WrapperGray>
      <SiteWidth>
        <WrapGray>
          <Left>
            <Contact>
              <Logo textColor="white" styles={{ marginBottom: '15px' }} />
              <InfoWrapper>
                {createInfo('map', ADDRESS, MAP_LINK, true)}
              </InfoWrapper>
              <InfoWrapper>
                {createInfo('phone', '013 / 333 - 513', 'tel:013333513')}
              </InfoWrapper>
              <InfoWrapper>
                {createInfo('phone', '064 / 644 - 47 - 01', 'tel:0646444701')}
                {createInfo('phone', '064 / 644 - 47 - 03', 'tel:0646444703')}
                {createInfo('phone', '064 / 644 - 47 - 09', 'tel:0646444709')}
              </InfoWrapper>
              <InfoWrapper>
                {createInfo('clock', 'Ponedeljak - Petak: 09 - 20')}
                {createInfo('clock', 'Subota - Nedelja: 09 - 14')}
              </InfoWrapper>
            </Contact>
          </Left>
          <Right>
            <Icons>
              <Link
                href="https://www.facebook.com/ambulantavivavet"
                target="_blank"
                rel="noopener"
              >
                <Facebook opacity={0.5} />
              </Link>
              <Link
                href="mailto:vivavet.rs@gmail.com"
              >
                <Mail opacity={0.5} />
              </Link>
              {/* <Instagram size={20} opacity={0.5} /> */}
            </Icons>
          </Right>
        </WrapGray>
      </SiteWidth>
    </WrapperGray>
    <WrapperBlack>
      <SiteWidth>
        <WrapBlack>
          <InfoWrapper>
            <BottomLabel>
              Copyright © 2019, Vivavet Pančevo. Sva prava zadržana.
            </BottomLabel>
            <BottomLabel style={{ opacity: 0.3 }}>
              Powered by Ivan Prtljaga™
            </BottomLabel>
          </InfoWrapper>
        </WrapBlack>
      </SiteWidth>
    </WrapperBlack>
  </FooterWrapper>
);
