import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Banner from '../components/Banner';

export default () => (
  <StaticQuery
    query={graphql`
      query BannerQuery {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: {regex: "/posts/"}
            frontmatter: {
              id: { eq: "banner" }
            }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
              html
            }
          }
        }
      }
    `}
    render={(data) => {
      const allData = data.allMarkdownRemark.edges[0].node;
      return (
        <Banner
          content={allData.html}
        />
      );
    }}
  />
);
