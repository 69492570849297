import styled from 'styled-components';

import { antialias, noJitter } from '../../utils';

const Paragraph = styled.p`
  ${antialias};
  ${noJitter};
  line-height: 1.5;
`;

export default Paragraph;
