import React from 'react';
import styled from 'styled-components';

import { Paragraph } from '../Text';
import SiteWidth from '../SiteWidth';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 50px 0 50px 0;

  ${props => props.style};
`;

const Divider = styled.div`
  background-color: ${props => (
    props.color === 'black'
      ? props.theme.colors.black.shade[0]
      : props.color === 'white'
        ? props.theme.colors.white.shade[1]
        : props.theme.colors.primary.shade[0]
  )};
  width: 60px;
  height: 3px;
`;

const Title = styled(Paragraph)`
  font-size: 42px;
  font-weight: 600;
  padding-bottom: 15px;
`;

const Content = styled.div`
  width: 100%;
  padding-top: 22px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 65%;
  }

  p:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 16px;
  line-height: 25px;
`;

export default ({ id, title, item, divider, contentStyle, sectionStyle }) => (
  <SiteWidth>
    <Wrapper id={id} style={sectionStyle}>
      <Title>
        {title}
      </Title>
      <Divider color={divider} />
      <Content style={contentStyle}>
        <StyledParagraph
          as="div"
          dangerouslySetInnerHTML={{ __html: `${item}` }}
        />
      </Content>
    </Wrapper>
  </SiteWidth>
);
