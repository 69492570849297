import React from 'react';
import styled from 'styled-components';

import SiteWidth from '../SiteWidth';
import { Paragraph } from '../Text';

const Wrapper = styled.div`
  width: 100%;
  height: 25px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  background-color: ${({ theme }) => theme.colors.primary.base};
  display: flex;
  justify-content: center;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.4);
`;

const HeadContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 500;
`;

const StyledLabel = styled(Paragraph)`
  color: white;
  letter-spacing: 2px;
`;

const Numbers = styled.div`
  display: flex;
`;

const StyledNumber = styled(StyledLabel)`
  display: flex;
  justify-content: space-between;
  padding-left: 22px;
  line-height: 1em;
`;

const Heading = () => (
  <Wrapper>
    <SiteWidth>
      <HeadContent>
        <StyledLabel>Miloša Trebinjca 44, Pančevo</StyledLabel>
        <Numbers>
          <StyledNumber>064/644-47-01</StyledNumber>
          <StyledNumber>064/644-47-03</StyledNumber>
          <StyledNumber>064/644-47-09</StyledNumber>
        </Numbers>
      </HeadContent>
    </SiteWidth>
  </Wrapper>
);

export default Heading;
