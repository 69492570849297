import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import SiteWidth from '../SiteWidth';
import { Paragraph } from '../Text';

import logo from '../../assets/images/symbol-white.svg';
import text from '../../assets/images/text-white.svg';

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        banner: file(relativePath: { eq: "assets/images/banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.banner.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor="#040e18"
        />
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  position: absolute !important; /* gatsby */
  top: 0; right: 0; left: 0; bottom: 0;
  width: 100%; height: 100%;
  background-position: 60% 75%;
  z-index: -1;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 655px;
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;

const Fade = styled.div`
  position: absolute;
  top: 0; right: 0; left: 0; bottom: 0;
  background: #191919;
  opacity: 0.5;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  z-index: 999999;
  padding-bottom: 60px;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    justify-content: flex-end;
    align-items: normal;
  }
  @media (min-height: 640) {
    padding-bottom: 85px;
  }
`;

const Text = styled(Paragraph)`
  color: white;
  font-size: 18px;
  line-height: 1.5;
  padding-top: 40px;
  font-weight: 600;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 55%;
  }
`;

const Logo = styled.div`
  width: 290px;
  height: 130px;
  display: flex;
  align-items: center;

  @media (max-width: 320px) {
    flex-direction: column;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    justify-content: center;
  }
`;

const LogoImg = styled.img`
  height: 100%;

  @media (max-width: 320px) {
    padding-bottom: 15px;
  }
`;

const LogoText = styled.img`
  height: 30%;
  padding-left: 10px;
`;

export default ({ content }) => (
  <Wrapper>
    <StyledBackgroundSection className="banner" />
    <Fade />
    <SiteWidth>
      <Content>
        <Logo>
          <LogoImg src={logo} />
          <LogoText src={text} />
        </Logo>
        <Text as="div" dangerouslySetInnerHTML={{ __html: `${content}` }} />
      </Content>
    </SiteWidth>
  </Wrapper>
);
