import React from 'react';
import styled from 'styled-components';

import logo from '../assets/images/symbol.svg';
import logoWhite from '../assets/images/symbol-white.svg';
import text from '../assets/images/text.svg';
import textWhite from '../assets/images/text-white.svg';

const Styled = styled.div`
  width: 129px;
  height: 52px;
  display: flex;
  align-items: center;

  ${props => props.styles};
`;

const LogoImg = styled.img`
  height: 100%;
`;

const LogoText = styled.img`
  height: 30%;
  padding-left: 10px;
`;

const Logo = ({ textColor, imageColor, styles }) => (
  <Styled style={styles}>
    <LogoImg src={imageColor === 'white' ? logoWhite : logo} alt="logo" />
    <LogoText src={textColor === 'white' ? textWhite : text} />
  </Styled>
);

export default Logo;
